import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { deleteData } from "../../../../actions/fetchDataAction";

const DeleteConfirmationModal = ({
  isOpen,
  onClose,
  deleteItem
}) => {


  const dispatch = useDispatch();




  const handelDelete = ()=>{

  dispatch(deleteData({id:deleteItem.id}))


  }

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>Confirm Delete</DialogTitle>
      <DialogContent>
        <DialogContentText>

          Warning: Are you sure you want to delete all data associated with this vehicle? This action cannot be undone. Once deleted, the information cannot be recovered. Are you absolutely certain you wish to proceed?
        </DialogContentText>
       

      
          <Typography>

            <>
            <br/>
            {deleteItem.description} 
            </>

          </Typography>

      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} variant="b2">
          Cancel
        </Button>
        <Button
          variant="b1"
          onClick={()=>handelDelete()}

        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmationModal;
