import { createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
import { deleteData, downloadExcel, getFetchData } from "../actions/fetchDataAction";

const initialState = {
    Object:null,
    loading : 'idle',
    hasError: false,
    errorMessage: '',
    excelObject :{
        loading : 'idle',
        hasError: false,
        errorMessage: '',
    },
    deleteObject :{
        loading : 'idle',
        hasError: false,
        message: '',

    }
}
const fetchDataSlice = createSlice({
    name: 'fetchData',
    initialState,
    reducers: {
         resetState: () => {
            return initialState;
        }

    },
    extraReducers: (builder) => {
        builder.addCase(PURGE, () => {
            return initialState;
        })
            .addCase(getFetchData.pending, (state) => {
                state.loading = 'pending';
                state.hasError = false
            })
            .addCase(getFetchData.fulfilled, (state, action) => {
                state.loading = 'succeeded';
                state.hasError = false;
                state.Object = action.payload ;
                state.errorMessage = ""


            })
            .addCase(getFetchData.rejected, (state, action) => {
                state.loading = 'failed';
                state.hasError = true;
                state.errorMessage = action.payload
            })
            .addCase(downloadExcel.pending, (state,action) => {
                state.excelObject.loading = "pending";
                state.excelObject.hasError = false;
              })
              .addCase(downloadExcel.fulfilled, (state) => {
                state.excelObject.loading = "succeeded";


              })
              .addCase(downloadExcel.rejected, (state, action) => {
                state.excelObject.loading = "failed";
                state.excelObject.hasError = true;
                state.excelObject.errorMessage = action.payload;
              })
              
              .addCase(deleteData.pending, (state,action) => {
                state.deleteObject.loading = "pending";
                state.deleteObject.hasError = false;
              })
              .addCase(deleteData.fulfilled, (state,action) => {
                state.deleteObject.loading = "succeeded";
                state.deleteObject.message = action.payload;


              })
              .addCase(deleteData.rejected, (state, action) => {
                state.deleteObject.loading = "failed";
                state.deleteObject.hasError = true;
                state.deleteObject.message = action.payload;
              });

    }
});
export const {  resetState } = fetchDataSlice.actions;

export default fetchDataSlice.reducer;