import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosInstanceService } from '../services/axiosInstance'
import { apiRoute } from '../util/constant'
import { saveAs } from 'file-saver'; 




export const getFetchData = createAsyncThunk(
    'dashboard/fetchData',
    async ({ query }, { rejectWithValue }) => {
        try {
            const instance = await AxiosInstanceService.getAxiosInstance();
            const response = await instance.get(`${apiRoute.getFetchData}?${query}`)
            return response.data;
        } catch (error) {
            // return custom error message from backend if present
            if (error.response && error.response.data.message) {
                return rejectWithValue(error.response.data.message)
            } else {
                return rejectWithValue(error.message)
            }
        }
    }
);






export const deleteData = createAsyncThunk(
  'dashboard/deleteData',
  async ({ id }, { rejectWithValue }) => {
      try {
          const instance = await AxiosInstanceService.getAxiosInstance();
          const response = await instance.delete(`${apiRoute.getVehicleInfo}${id}`)
          return response.data;
      } catch (error) {
          // return custom error message from backend if present
          if (error.response && error.response.data.message) {
              return rejectWithValue(error.response.data.message)
          } else {
              return rejectWithValue(error.message)
          }
      }
  }
);





export const downloadExcel = createAsyncThunk(
    'dashboard/downloadExcel',
    async ({ query }, { rejectWithValue }) => {
      try {
        const instance = await AxiosInstanceService.getAxiosInstance();
        const response = await instance.get(`${apiRoute.getFetchDataExcel}?${query}`, {
          responseType: 'blob', // Set the response type to 'blob'
        });
        // Create a Blob from the response data
        const blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet2',
        });
  





        const contentDispositionHeader = response.headers['content-disposition'];
        const match = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDispositionHeader);



        const filename = match && match[1] ? match[1] : 'data.xlsx'; // Default filename if not found
        saveAs(blob, filename);

  
        
      } catch (error) {
        // return custom error message from backend if present
        if (error.response && error.response.data.message) {
          return rejectWithValue(error.response.data.message);
        } else {
          return rejectWithValue(error.message);
        }
      }
    }
  );

